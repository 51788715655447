@import url(https://fonts.googleapis.com/css?family=Open+Sans);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  font-family: "Open Sans", sans-serif !important;
}
body{
  font-family: "Open Sans", sans-serif !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/**
#7a99ac
*/
.show-cell {
  background: white;
  border-left: 1px solid lightgrey !important;
  border-right: 1px solid lightgrey !important;
  border-bottom: 1px solid lightgrey !important;
}

.show-name {
  font-weight: bold;
}

.show-presenter {
  font-style: italic;
}

#root{
  background-image: url(/static/media/fondo-calculadoras-V4.3b629520.jpg);
  background-repeat: no-repeat;
  background-size: 100% auto;
}


#customers {
  font-family: "Open Sans", sans-serif !important;
  border-collapse: collapse;
  width: 100%;
}

#customers td {
  border: 1px solid #7a99ac;
  padding: 8px;
}

#customers th{
  border-top: 1px solid #7a99ac;
}

#customers:nth-child(1) > tr:nth-child(1) > th:nth-child(1) {
	border-left: 1px solid #7a99ac;
}

#customers:nth-child(1) > tr:nth-child(1) > th:nth-child(4) {
	border-right: 1px solid #7a99ac;
}



#customers tr:nth-child(even){background-color: #e8eaeb;}



#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: white;
}

#customers2 {
  font-family: "Open Sans", sans-serif !important;
  border-collapse: collapse;
  width: 100%;
}

#customers2 td {
  border: 1px solid #7a99ac;
  padding: 8px;
}

#customers2 th{
  border-top: 1px solid #7a99ac;
}

#customers2:nth-child(1) > tr:nth-child(1) > th:nth-child(1) {
	border-left: 1px solid #7a99ac;
}




#customers tr:nth-child(even){background-color: #e8eaeb;}



#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: white;
  text-align: center;
}

label, .ms-Dropdown-caretDown{
  color: #7a99ac!important;
}
.ms-TextField-fieldGroup, .ms-Dropdown, .ms-Dropdown-title{
  border-color: #7a99ac!important;
}
.ms-TextField-suffix{
  background-color: #7a99ac!important;
  color: #ffffff!important;
}
.ms-Checkbox-checkbox, .ms-Checkbox-text{
  border-color: #7a99ac!important;
  color: #7a99ac!important;
}
hr{
  color: #7a99ac!important;
}

.ms-Pivot-text{
  font-size: 14px!important;
}
.ms-Pivot-link{
  padding-left: 10px!important;
  padding-right: 10px!important;
}
.ms-Pivot-link:hover{
  background-color: rgba(243, 242, 241, 0)!important;
}

.ms-TooltipHost{
  color: #707c8c;
  font-weight: 600;
}
/*
.ms-Breadcrumb-listItem{
  text-shadow: 1px 0 0 rgba(255, 255, 255, 0.815), -1px 0 0 rgba(255, 255, 255, 0.815), 0 1px 0 rgba(255, 255, 255, 0.815), 0 -1px 0 rgba(255, 255, 255, 0.815), 1px 1px rgba(255, 255, 255, 0.815), -1px -1px 0 rgba(255, 255, 255, 0.815), 1px -1px 0 rgba(255, 255, 255, 0.815), -1px 1px 0 rgba(255, 255, 255, 0.815);
}
*/

.ms-Button{
  border-radius: 40px;
  font-family: "Open Sans", sans-serif !important;
  
}
.ms-Button--primary{
  font-weight: 600 !important;
  font-size: 14px !important;
  text-transform: uppercase !important;
}

#menuADM > div > button > span > span > span{
  font-size: 18px!important;
}

.ms-Breadcrumb-item{
  font-size: 14px!important;
}
